import { useEffect, useState } from 'react';
import { subscribeToTopic } from '../mqtt/mqttClient';

export const useMqtt = (topic: string) => {
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    const handleNewMessage = (newMessage: string) => {
      setMessage(newMessage);
    };

    subscribeToTopic(topic, handleNewMessage);

    return () => {
      // Cleanup aquí si fuera necesario
    };
  }, [topic]);

  return message;
};
