import React, { useEffect, useState } from 'react';
import MessageDisplay from '../components/MessageDisplay';
import { fetchData } from '../api/apiClient';
import { useMqtt } from '../hooks/useMqtt';

const Home: React.FC = () => {
  const [apiData, setApiData] = useState<any>(null);
  const mqttMessage = useMqtt('your/mqtt/topic');

  useEffect(() => {
    const getData = async () => {
      const data = await fetchData('/your-endpoint');
      setApiData(data);
    };

    getData();
  }, []);

  return (
    <div>
      <h1>Inicio</h1>
      <MessageDisplay message={mqttMessage ? { content: mqttMessage, timestamp: new Date().toISOString() } : null} />
      <div>
        <h3>Datos del API:</h3>
        <pre>{JSON.stringify(apiData, null, 2)}</pre>
      </div>
    </div>
  );
};

export default Home;
