import mqtt, { MqttClient } from 'mqtt';

const mqttClient: MqttClient = mqtt.connect(process.env.REACT_APP_MQTT_URL || '');

export const subscribeToTopic = (topic: string, callback: (message: string) => void) => {
  mqttClient.on('connect', () => {
    console.log(`Connected to MQTT broker`);
    mqttClient.subscribe(topic, (err) => {
      if (err) {
        console.error(`Failed to subscribe to ${topic}`);
      }
    });
  });

  mqttClient.on('message', (receivedTopic, message) => {
    if (receivedTopic === topic) {
      callback(message.toString());
    }
  });
};

export default mqttClient;
