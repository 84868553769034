import React from 'react';
import { Message } from '../types/Message';

interface Props {
  message: Message | null;
}

const MessageDisplay: React.FC<Props> = ({ message }) => {
  return (
    <div>
      <h3>Último Mensaje Recibido</h3>
      {message ? (
        <p>{message.content}</p>
      ) : (
        <p>No hay mensajes aún.</p>
      )}
    </div>
  );
};

export default MessageDisplay;
